@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .heroButton {
    @apply px-6 py-2 border border-[#242424] rounded-full uppercase text-xs tracking-widest text-gray-500 transition-all hover:border-[#F7AB0A]/40 hover:text-[#F7AB0A]/40;
  }
  .contactInput {
    @apply outline-none bg-slate-400/10 rounded-md border-b px-6 py-4 border-[#242424] text-gray-500 placeholder-gray-500 transition-all focus:border-green-400/40 focus:text-green-500 hover:border-green-400/40;
  }
}